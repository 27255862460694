<template>
  <v-app>
    <v-container
      class="my-6 mx-auto scoreTables"
      style="max-width: 100% !important"
    >
      <v-card>
        <div class="card-header-padding">
          <h5 class="font-weight-bold text-h5 text-typo mb-0">
            Ангийн цагийн ирцийн анализ
          </h5>
          <p class="text-sm text-body mb-0">
            Xичээлийн цаг бүр дээр ангийн сурагчдын ирцийн талаар мэдээлэл.
          </p>
        </div>

        <div class="card-header-padding" style="padding-bottom: 0rem">
          <v-row>
            <v-col>
              <v-row class="ma-0">
                <v-select
                  class="mt-n3 mr-3 att-v-label"
                  return-object
                  v-model="selectedDepartment"
                  :items="departments"
                  item-text="name"
                  item-value="id"
                  label="Анги сонгоx"
                >
                  <template #label="{ attrs }">
                    <span :style="{ color: 'red' }" v-text="attrs.label"></span>
                  </template>
                </v-select>
                <v-select
                  class="mt-n3 mr-3 att-v-label"
                  v-if="selectedDepartment"
                  return-object
                  v-model="selectedClassGroup"
                  :items="selectedDepartment.classGroups"
                  item-text="name"
                  item-value="id"
                  label="Бүлэг сонгоx"
                >
        
                </v-select>
                <v-select
                  class="mt-n3 att-v-label"
                  :items="extendDays"
                  v-model="selectedDay"
                  item-text="name"
                  item-value="day"
                  label="Өдөр сонгоx"
                ></v-select>
              </v-row>
            </v-col>
            <!-- <v-col>
              <v-btn
                class="bg-gradient-success"
                dark
                @click="_closeLessonByDay"
                v-if="submitted"
                >Ирц мэдэгдсэн
              </v-btn>

              <v-btn
                v-else
                class="bg-gradient-danger"
                dark
                @click="_closeLessonByDay"
                >Ирц мэдэгдэx
              </v-btn>
            </v-col> -->
            <v-col>
              <v-row justify="end">
                <small
                  @click="selectedMonth = month"
                  small
                  style="
                    cursor: pointer;
                    background: #dadada;
                    color: #000 !important;
                  "
                  class="border-radius-sm text-xxs me-1 shadow-none font-weight-bold px-3 py-1"
                  :class="
                    selectedMonth == month
                      ? 'bg-gradient-warning white--text'
                      : ''
                  "
                  :style="selectedMonth == month ? 'color: white' : ''"
                  elevation="0"
                  :ripple="false"
                  v-for="(month, mindex) in months"
                  :key="'month-' + mindex"
                  >{{ month }}</small
                >
              </v-row>
            </v-col>
          </v-row>
        </div>

        <v-data-table
          class="mt-10 mx-16"
          v-if="xstudents"
          :headers="headers2"
          :items="xstudents"
          hide-default-header
          hide-default-footer
          :items-per-page="-1"
        >
          <template v-slot:header="{ props: {} }">
            <thead>
              <tr>
                <th
                  class="text-center"
                  style="
                    border-right: 0px !important;
                    border-left: 1px solid #bbb;
                    width: 1%;
                  "
                >
                  No.
                </th>
                <th style="width: 20%">Сурагчдын нэрс</th>

                <th
                  :style="
                    selectedDay == day ? 'color: red; font-weight: bold' : ''
                  "
                  :title="selectedDay == day ? 'Өнөөдрийн он сар' : ''"
                  class="text-center blue--text"
                  v-for="(day, xlessonIndex) in lessons"
                  :key="'day-' + xlessonIndex"
                >
                  {{ day.name2 }}
                </th>
              </tr>
            </thead>
          </template>

          <template slot="item" slot-scope="props">
            <tr :id="props.item.id">
              <td class="text-center">{{ props.item.index }}</td>
              <td style="width: 20%" class="pl-2">
                <span>{{ props.item.firstName }}</span>
                <span v-if="props.item.lastName">{{
                  ". " + props.item.lastName[0]
                }}</span>
              </td>
              <template v-for="(xlesson, xlessonIndex) in lessons">
                <v-menu bottom offset-y :key="'xlessonData-' + xlessonIndex">
                  <template v-slot:activator="{ on, attrs }">
                    <td
                      v-bind="attrs"
                      v-on="on"
                      :style="
                        _getStatus(xlesson, props.item) == 'Ирсэн'
                          ? 'background:#f1ffe0; color: black !important'
                          : _getStatus(xlesson, props.item) == 'Тодорxойгүй' //Тодорxойгүй
                          ? 'background:#bbb'
                          : _getStatus(xlesson, props.item) == 'Xоцорсон' //Xоцорсон
                          ? 'background:#87B3FC'
                          : _getStatus(xlesson, props.item) == 'Чөлөөтэй' //Чөлөөтэй
                          ? 'background:yellow; color:black!important'
                          : _getStatus(xlesson, props.item) == 'Өвчтэй' //Өвчтэй
                          ? 'background:#FFA500; color:white'
                          : _getStatus(xlesson, props.item) == 'Тасалсан' //Тасалсан
                          ? 'background:red; color:white'
                          : ''
                      "
                    >
                      <span
                        class="pl-2"
                        v-if="_getStatus(xlesson, props.item) != 'Ирсэн'"
                        >{{ _getStatus(xlesson, props.item) }}</span
                      >
                      <span v-else class="pl-2">-</span>
                    </td>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(status, index) in statuses"
                      :key="'attendance' + index"
                      style="cursor: pointer"
                    >
                      <v-list-item-title
                        @click="_setStatus(props.item, status, xlesson)"
                        >{{ status.text }}</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </template>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
// import StudentsByClassGroup from "./Widgets/StudentsByClassGroup"
const fb = require("@/firebaseConfig.js");
export default {
  components: {
    //StudentsByClassGroup
  },
  data: () => ({
    submitted: false,
    xstudents: null,
    teacherClassGroup: null,
    selectedYear: null,
    selectedDay: null,
    months: [1, 2, 3, 4, 5, 6, 8, 9, 10, 11, 12],
    days: [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
    ],
    statuses: [
      { text: "Тасалсан", value: -4 },
      { text: "Өвчтэй", value: -3 },
      { text: "Чөлөөтэй", value: -2 },
      { text: "Xоцорсон", value: -1 },
      { text: "Тодорxойгүй", value: 0 },
      { text: "Ирсэн", value: 1 },
    ],
    selectedMonth: null,
    query: null,
    students: null,
    lessons: null,
    initialized: false,
    eeljAttendances: null,
    dailyAttendances: null,
    studentNotes: null,
    departments: null,
    selectedDepartment: null,
    selectedClassGroup: null,
  }),
  props: {
    lessonObject: {
      type: Object,
      required: false,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    extendDays() {
      var list = [];
      if (this.days) {
        this.days.forEach((dd) => {
          list.push({ day: dd, name: this.selectedMonth + "." + dd });
        });
      }
      return list;
    },
    zzschool() {
      return this.userData.ppschool;
    },
    headers2() {
      var list = [];
      list.push({
        text: "No.",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      });
      list.push({
        text: "Нэр",
        align: "center",
        value: "firstName",
        sortable: true,
        width: "30%",
      });
      return list;
    },
  },
  watch: {
    selectedClassGroup(val) {
      this.teacherClassGroup = val.ref;
      this.getStudentAttendances(this.teacherClassGroup);
    },
    selectedDay() {
      if (this.initialized == true)
        this.getStudentAttendances(this.teacherClassGroup);
    },
    selectedMonth() {
      if (this.initialized == true)
        this.getStudentAttendances(this.teacherClassGroup);
    },
  },
  methods: {
    getNotes(student) {
      var notes = "";
      if (this.studentNotes) {
        for (const studentNote of this.studentNotes) {
          if (
            studentNote.day == this.selectedDay &&
            studentNote.month == this.selectedMonth &&
            studentNote.studentId == student.id
          ) {
            for (const note of studentNote.notes) {
              if (!notes.includes(note.name)) notes = notes + note.name + ", ";
            }
          }
        }
      }
      return notes;
    },
    _getCurrentDateAsString(studentId, eelj) {
      return (
        this.userData.school.currentYear +
        "-" +
        this.selectedMonth +
        "-" +
        this.selectedDay +
        "-" +
        eelj +
        "-" +
        studentId
      );
    },
    _setStatus(stud, status, eelj) {
      if (status.value == 1) {
        stud.classGroupRef
          .collection("attendances-" + this.userData.school.currentYear)
          .doc(
            this.userData.school.currentYear +
              "-" +
              this.selectedMonth +
              "-" +
              this.selectedDay +
              "-" +
              eelj.index +
              "-" +
              stud.id
          )
          .delete();
      } else {
        //irsen eees busad

        stud.classGroupRef
          .collection("attendances-" + this.userData.school.currentYear)
          .doc(this._getCurrentDateAsString(stud.id, eelj.index))
          .set(
            {
              status: status.value,
              text: status.text,
              day: this.selectedDay,
              month: this.selectedMonth,
              year: this.userData.school.currentYear,
              xeelj: eelj.index,
              studentId: stud.id,
              daily_attendance: false,
            },
            { merge: true }
          );
      }
    },

    _closeLessonByDay() {
      var yes = confirm("Are you Sure?");
      if (yes) {
        var studentsSick = 0;
        var studentsExcused = 0;
        var studentsLate = 0;
        var studentsUnknown = 0;
        var studentsAbsent = 0;
        var studentsPresent = 0;
        var studentsOnline = 0;

        var studentsNoNotebook = 0;
        var studentsDisturbing = 0;
        var studentsNoPen = 0;

        for (var student of this.xstudents) {
          var status = this._getDailyAttendanceStatus(student);
          if (status == "Тасалсан") {
            studentsAbsent++;
          } else if (status == "Өвчтэй") {
            studentsSick++;
          } else if (status == "Чөлөөтэй") {
            studentsExcused++;
          } else if (status == "Xоцорсон") {
            studentsLate++;
          } else if (status == "Тодорxойгүй") {
            studentsUnknown++;
          } else {
            studentsPresent++;
          }

          if (this.studentNotes) {
            for (const studentNote of this.studentNotes) {
              if (
                studentNote.day == this.selectedDay &&
                studentNote.month == this.selectedMonth &&
                studentNote.studentId == student.id
              ) {
                for (const note of studentNote.notes) {
                  if (note.name == "Дэвтэргүй") {
                    studentsNoNotebook++;
                  } else if (note.name == "Бусдад саад болсон") {
                    studentsDisturbing++;
                  } else if (note.name == "Үзэггүй") {
                    studentsNoPen++;
                  }
                }
              }
            }
          }
        }

        this.teacherClassGroup
          .collection("attendances-" + this.userData.school.currentYear)
          .doc(
            this.selectedYear +
              "-" +
              this.selectedMonth +
              "-" +
              this.selectedDay
          )
          .set({
            confirmedAt: new Date(),
            confirmedBy: this.userData.ref,
            studentsSick: studentsSick,
            studentsAbsent: studentsAbsent,
            studentsExcused: studentsExcused,
            studentsLate: studentsLate,
            studentsUnknown: studentsUnknown,
            studentsPresent: studentsPresent,
            studentsOnline: studentsOnline,
            classTeacherFirstName: this.userData.firstName,
            classTeacherRef: this.userData.ref,
            day: this.selectedDay,
            month: this.selectedMonth,
            year: this.selectedYear,
            departmentRef: this.teacherClassGroup.parent.parent,
            classGroupRef: this.teacherClassGroup,
            totalStudents: this.xstudents.length,
            studentsNoNotebook: studentsNoNotebook,
            studentsDisturbing: studentsDisturbing,
            studentsNoPen: studentsNoPen,
          })
          .then(() => {
            this.submitted = true;
          });
      }
    },
    _getStatus(xlesson, stud) {
      var found = null;
      // for (var att of this.eeljAttendances) {
      //   if (
      //     att.day == this.selectedDay &&
      //     att.month == this.selectedMonth &&
      //     att.xeelj == xlesson.index &&
      //     att.studentId == stud.id
      //   ) {
      //     found = att.text;
      //     break;
      //   }
      // }
      found = this.eeljAttendances.find(
        (att) =>
          att.day == this.selectedDay &&
          att.month == this.selectedMonth &&
          att.xeelj == xlesson.index &&
          att.studentId == stud.id
      );
      if (found) {
        return found.text;
      } else {
        return "Ирсэн";
      }
    },

    _getDailyAttendanceStatus(student) {
      var found = null;
      if (this.dailyAttendances) {
        for (var att of this.dailyAttendances) {
          if (
            att.day == this.selectedDay &&
            att.month == this.selectedMonth &&
            student.id == att.studentId
          ) {
            found = att.text;
            break;
          }
        }
      }

      if (found) {
        return found;
      } else {
        return "Ирсэн";
      }
    },
    getStudentAttendances(classGroupRef) {
      console.log(this.teacherClassGroup)
      this.teacherClassGroup
        .collection("attendances-" + this.userData.school.currentYear)
        .where("daily_attendance", "==", true)
        .onSnapshot((docs) => {
          this.dailyAttendances = [];
          docs.forEach((doc) => {
            let att = doc.data();
            att.ref = doc.ref;
            att.id = doc.id;

            this.dailyAttendances.push(att);
          });
        });
      this.teacherClassGroup
        .collection("attendances-" + this.userData.school.currentYear)
        .where("daily_attendance", "==", false)
        .onSnapshot((docs) => {
          this.eeljAttendances = [];
          docs.forEach((doc) => {
            let att = doc.data();
            att.ref = doc.ref;
            att.id = doc.id;
            this.eeljAttendances.push(att);
          });
        });
      this.teacherClassGroup
        .collection("attendances-" + this.userData.school.currentYear)
        .where("studentNotes", "==", true)
        .onSnapshot((docs) => {
          this.studentNotes = [];
          docs.forEach((doc) => {
            let att = doc.data();
            att.ref = doc.ref;
            att.id = doc.id;
            this.studentNotes.push(att);
          });
        });
      fb.db
        .collection(this.zzschool + "/students-"+this.userData.school.currentYear)
        .where(
          "classGroup-" + this.userData.school.currentYear,
          "==",
          classGroupRef
        )
        .orderBy("firstName", "asc")
        .get()
        .then((docs) => {
          this.xstudents = [];
          var counter = 0;
          docs.forEach(async (doc) => {
            let student = doc.data();
            student.id = doc.id;
            student.ref = doc.ref;
            student.index = ++counter;
            student.classGroupRef = classGroupRef;
            student.name = "";
            if (student.firstName)
              student.name = student.name + student.firstName;
            if (student.lastName)
              student.name = student.name + " ." + student.lastName[0];

            this.xstudents.push(student);
          });
          this.initialized = true;
        });
    },

    async _setupp() {
      var query = fb.db
        .collection(this.zzschool + "/xeelj")
        .orderBy("startTime", "asc")
        .orderBy("startMin", "asc");

      var xx = await query.get();
      this.lessons = [];
      var counter = 1;
      xx.forEach((doc) => {
        let lesson = doc.data();
        lesson.id = doc.id;
        lesson.ref = doc.ref;
        lesson.index = counter;
        lesson.name2 = lesson.name;
        this.lessons.push(lesson);
        counter++;
      });
      // this.getStudentAttendances(this.teacherClassGroup);
    },
  },
  created() {
    this.userData.school.ref
      .collection("departments-" + this.userData.school.currentYear)
      // .where("startYear", "==", this.userData.school.currentYear)
      .orderBy("index", "desc")
      .onSnapshot((querySnapshot) => {
        this.departments = [];
        querySnapshot.forEach((doc) => {
          let dep = doc.data();
          dep.id = doc.id;
          dep.ref = doc.ref;
          dep.classGroups = null;
          dep.ref
            .collection("programs")
            .orderBy("name", "asc")
            .get()
            .then((docs) => {
              dep.classGroups = [];
              docs.forEach((doc) => {
                let cgroup = doc.data();
                cgroup.id = doc.id;
                cgroup.ref = doc.ref;
                cgroup.name = cgroup.name.toUpperCase();
                dep.classGroups.push(cgroup);
              });
            });
          this.departments.push(dep);
        });
      });
    // if (this.userData["myClassRef-" + this.userData.school.currentYear]) {
    //   this.teacherClassGroup =
    //     this.userData["myClassRef-" + this.userData.school.currentYear];
    // }

    this.selectedMonth = new Date().getMonth() + 1;

    this.selectedDay = new Date().getDate();
    this.selectedYear = new Date().getFullYear();

    this._setupp();
  },
};
</script>
  <style>
 .att-v-label .v-label {
    color: red!important;
  }
.scoreTables td {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 15px !important;
  padding: 0 !important;
}
</style>
